import React from "react";
import classes from './Team.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/Team/image_01.webp';
import image_02 from '../../../assets/img/HomePage/Team/image_02.webp';
import image_03 from '../../../assets/img/HomePage/Team/image_03.webp';
import image_04 from '../../../assets/img/HomePage/Team/image_04.webp';
import image_05 from '../../../assets/img/HomePage/Team/image_05.webp';
import image_06 from '../../../assets/img/HomePage/Team/image_06.webp';
import image_07 from '../../../assets/img/HomePage/Team/image_07.webp';

const Team = () => {
    const { t } = useTranslation();

    const cards = [
        {
            title: 'Marla',
            text: 'head',
            image: image_01,
        },
        {
            title: 'Sasha',
            text: 'Teamlead',
            image: image_02,
        },
        {
            title: 'Andrew',
            text: 'BizDev',
            image: image_03,
        },
        {
            title: 'Alice',
            text: 'BizDev',
            image: image_04,
        },
        {
            title: 'Frankie',
            text: 'BizDev',
            image: image_05,
        },
        {
            title: 'Shady',
            text: 'BizDev',
            image: image_06,
        },
        {
            title: 'Vladislav',
            text: 'BizDev',
            image: image_07,
        },
    ];

    return (
        <section className="mt container">
            <div className={classes.team}>
                <h2 className={`${classes.title} font-100`}>
                    {t('team.title')}
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div ref={card.ref} key={index} className={classes.card}>
                            <img className={classes.image} src={card.image} alt='' />
                            <div className={classes.content}>
                                <p className={`${classes.contentTitle} font-35`}>
                                    {card.title}
                                </p>
                                <p className={`${classes.contentText} font-18-team`}>
                                    {card.text}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Team;
