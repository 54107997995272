import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import item from '../../../assets/img/HomePage/Banner/item.svg';
import bg from '../../../assets/img/HomePage/Banner/bg.webp';
import girl from '../../../assets/img/HomePage/Banner/girl.webp';

const Banner = ({ setFormActive }) => {
    const { t } = useTranslation();
    return (
        <section className={classes.bannerWrap}>
            <div className={classes.frame}></div>
            <img className={classes.bg} src={bg} alt=''/>
            <div className="container">
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <img className={classes.item} src={item} alt='' />
                        <h1 className={`${classes.title} font-140`}>
                            Continental
                        </h1>
                        <h2 className={`${classes.subtitle} font-25`}>
                            {t('banner.subtitle')}
                        </h2>
                        <img className={`${classes.item} ${classes.itemRev}`} src={item} alt='' />
                        <div className={classes.btn}>
                            <Button setFormActive={setFormActive}>
                                {t('btn.buy_traff')}
                            </Button>
                        </div>
                    </div>
                    <img className={classes.girl} src={girl} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default Banner;
