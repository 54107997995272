import React, { useRef } from "react";
import classes from './WeOffer.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/WeOffer/image_01.png';
import image_02 from '../../../assets/img/HomePage/WeOffer/image_02.png';
import image_03 from '../../../assets/img/HomePage/WeOffer/image_03.png';
import image_04 from '../../../assets/img/HomePage/WeOffer/image_04.png';
import item from '../../../assets/img/HomePage/WeOffer/item.png';

const WeOffer = () => {
    const { t } = useTranslation();
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const cards = [
        {
            ref: cardRef_01,
            text: 'we_offer.text_01',
            image: image_01,
        },
        {
            ref: cardRef_02,
            text: 'we_offer.text_02',
            image: image_02,
        },
        {
            ref: cardRef_03,
            text: 'we_offer.text_03',
            image: image_03,
        },
        {
            ref: cardRef_04,
            text: 'we_offer.text_04',
            image: image_04,
        },
    ];
    return (
        <section className="mt container">
            <div className={classes.weOffer}>
                <h2 className={`${classes.title} font-100`}>
                    {t('we_offer.title')}
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <img className={classes.image} src={card.image} alt=''/>
                            <img className={classes.item} src={item} alt=''/>
                            <div className={classes.content}>
                                <p className={`${classes.text} font-20`}>
                                    <Trans>
                                        {t(card.text)}
                                    </Trans>
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default WeOffer;
