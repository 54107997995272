import logo from '../assets/img/logo.svg';
import img1 from '../assets/img/HomePage/Banner/bg.webp';
import img2 from '../assets/img/HomePage/Banner/frame.webp';
import img3 from '../assets/img/HomePage/Banner/frame_mobile.webp';
import img4 from '../assets/img/HomePage/Banner/girl.webp';
import img5 from '../assets/img/HomePage/Banner/item.svg';

export const images = [
    logo,
    img1,
    img2,
    img3,
    img4,
    img5,
];
