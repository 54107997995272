import React from "react";
import classes from './Benefits.module.scss';
import { Trans, useTranslation } from "react-i18next";
import line from '../../../assets/img/HomePage/Benefits/line.svg';
import painting_01 from '../../../assets/img/HomePage/Benefits/painting_01.jpg';
import painting_02 from '../../../assets/img/HomePage/Benefits/painting_02.jpg';
import car from '../../../assets/img/HomePage/Benefits/car.png';

const cards = [
    {
        text_01: 'benefits.text_01',
        text_02: 'benefits.text_02',
    },
    {
        text_01: 'benefits.text_03',
        text_02: 'benefits.text_04',
    },
    {
        img: true,
    },
    {
        text_01: 'benefits.text_05',
        text_02: 'benefits.text_06',
    },
    {
        text_01: 'benefits.text_07',
        text_02: 'benefits.text_08',
    },
];

const Benefits = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.benefitsWrap}>
            <img className={classes.painting_01} src={painting_01} alt='' />
            <img className={classes.painting_02} src={painting_02} alt='' />
            <div className="mt container">
                <div className={classes.benefits}>
                    <div className={classes.cards}>
                        {cards.map((card, index) =>
                            card.img === true
                                ?
                                <img key={index} className={classes.car} src={car} alt='' />
                                :
                                <div key={index} className={classes.card}>
                                    <p className={`${classes.text_01} font-50-benefits`}>
                                        {t(card.text_01)}
                                    </p>
                                    <p className={`${classes.text_02} font-23`}>
                                        <Trans>
                                            {t(card.text_02)}
                                        </Trans>
                                    </p>
                                    <img className={classes.line} src={line} alt='' />
                                </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Benefits;
