import React from "react";
import classes from "./Footer.module.scss";
import { useTranslation } from "react-i18next";
import line from '../../../assets/img/Footer/line.svg';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className={`${classes.wrap} mt font-16 ovf-hidden`}>
      <div className={classes.footerWrap}>
        <div className="container">
          <div className={classes.footer}>
            <img className={classes.line} src={line} alt='' />
            <div className={classes.content}>
              <div className={classes.docs}>
                <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
                  {t('footer.docs.policy')}
                </a>
                <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
                  {t('footer.docs.terms')}
                </a>
                <a href="/docs/AML_POLICY.pdf" target="_blank">
                  {t('footer.docs.client_agree')}
                </a>
              </div>
              <p>
                {t('footer.rights')}
              </p>
              <p>
                Envyus Media5135 W Central AveWichita, KS 67212
              </p>
            </div>
            <img className={classes.line} src={line} alt='' />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
