import _ from 'lodash';
import React, { useEffect, useState } from "react";
import $ from 'jquery';
import axios from 'axios';
import classes from './Form.module.scss';
import { useTranslation, Trans } from "react-i18next";

const Form = ({ formActive, setFormActive }) => {
    const { t } = useTranslation();
    const [success, setSuccess] = useState(false);

    const [name, setName] = useState('');
    const [contacts, setContacts] = useState('');
    const [company, setCompany] = useState('');
    const [position, setPosition] = useState('');
    const [vertical, setVertical] = useState('');
    const [check, setCheck] = useState(false);

    const [nameValid, setNameValid] = useState(true);
    const [contactsValid, setContactsValid] = useState(true);
    const [companyValid, setCompanyValid] = useState(true);
    const [positionValid, setPositionValid] = useState('');
    const [verticalValid, setVerticalValid] = useState('');
    const [checkValid, setCheckValid] = useState(true);

    const setDataDefault = () => {
        setName('');
        setContacts('');
        setCompany('');
        setPosition('');
        setVertical('');
        setCheck(false);
        setNameValid(true);
        setContactsValid(true);
        setCompanyValid(true);
        setPositionValid(true);
        setVerticalValid(true);
        setCheckValid(true);
        setSuccess(false);
    };

    useEffect(() => {
        setDataDefault();
        formActive
            ? $('body').addClass("ovf-hidden ovf-padding")
            : $('body').removeClass("ovf-hidden ovf-padding");
    }, [formActive]);

    const validateString = (getter, setter) => {
        if (getter === '') {
            setter(false);
            return false;
        }
        setter(true);
        return true;
    };
    const validateCheck = () => {
        if (check === false) {
            setCheckValid(false);
            return false;
        }
        setCheckValid(true);
        return true;
    };

    const validateInputData = () => {
        let valid = true;
        const validArr = [];
        validArr.push(validateString(name, setNameValid));
        validArr.push(validateString(contacts, setContactsValid));
        validArr.push(validateString(company, setCompanyValid));
        validArr.push(validateString(position, setPositionValid));
        validArr.push(validateString(vertical, setVerticalValid));
        validArr.push(validateCheck());

        _.map(validArr, (item) => !item && (valid = false));

        return valid;
    };

    const sendMessage = async () => {
        const messageData = {
            name,
            contacts,
            company,
            position,
            vertical,
        };
        setDataDefault();
        try {
            const data = await axios.post('https://civbt.xyz/api/bot/DFpAWtygIZ', messageData);
            if (data.status === 200) {
                setSuccess(true);
            } else {
                throw ({ message: 'Something went wrong. Try again.' })
            }
        } catch (error) {
            alert(`${error.message}`)
        }
    };

    const submit = () => {
        if (validateInputData()) {
            sendMessage();
        }
        return;
    };

    useEffect(() => {
        !nameValid && validateString(name, setNameValid);
        // eslint-disable-next-line
    }, [name]);
    useEffect(() => {
        !contactsValid && validateString(contacts, setContactsValid);
        // eslint-disable-next-line
    }, [contacts]);
    useEffect(() => {
        !companyValid && validateString(company, setCompanyValid);
        // eslint-disable-next-line
    }, [company]);
    useEffect(() => {
        !positionValid && validateString(position, setPositionValid);
        // eslint-disable-next-line
    }, [position]);
    useEffect(() => {
        !verticalValid && validateString(vertical, setVerticalValid);
        // eslint-disable-next-line
    }, [vertical]);
    useEffect(() => {
        !checkValid && validateCheck();
        // eslint-disable-next-line
    }, [check]);

    return (
        <section className={`${classes.formWrap} ${formActive && classes.formWrapActive}`}>
            <div onClick={() => setFormActive(false)} className={classes.closeArea}></div>
            <div className={`container ${classes.formContainer}`}>
                <div className={`${classes.form} ${success && classes.formSuccess}`}>
                    <div onClick={() => setFormActive(false)} className={classes.close}></div>
                    <div className={classes.successPopup}>
                        <div className={classes.successPopupImg}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="23" viewBox="0 0 32 23" fill="none">
                                <path d="M11.625 22.8431L0.6875 11.9034L3.77844 8.8125L11.625 16.6569L28.2172 0.0625L31.3125 3.15781L11.625 22.8431Z" fill="#FFF" />
                            </svg>
                        </div>
                        <p className={classes.successPopupText}>
                            <Trans>
                                {t('form.form_success')}
                            </Trans>
                        </p>
                    </div>
                    <div className={classes.formContent}>
                        <div className={classes.inputs}>
                            <div className={`${classes.inputWrap} ${!nameValid && classes.borderRed}`}>
                                <input
                                    className={classes.input}
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder={t('form.name')}
                                />
                            </div>
                            <div className={`${classes.inputWrap} ${!contactsValid && classes.borderRed}`}>
                                <input
                                    className={classes.input}
                                    type="text"
                                    value={contacts}
                                    onChange={(e) => setContacts(e.target.value)}
                                    placeholder={t('form.contacts')}
                                />
                            </div>
                            <div className={`${classes.inputWrap} ${!companyValid && classes.borderRed}`}>
                                <input
                                    className={classes.input}
                                    type="text"
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                    placeholder={t('form.company')}
                                />
                            </div>
                            <div className={`${classes.inputWrap} ${!positionValid && classes.borderRed}`}>
                                <input
                                    className={classes.input}
                                    type="text"
                                    value={position}
                                    onChange={(e) => setPosition(e.target.value)}
                                    placeholder={t('form.position')}
                                />
                            </div>
                            <div className={`${classes.inputWrap} ${!verticalValid && classes.borderRed}`}>
                                <input
                                    className={classes.input}
                                    type="text"
                                    value={vertical}
                                    onChange={(e) => setVertical(e.target.value)}
                                    placeholder={t('form.vertical')}
                                />
                            </div>
                            <div className={`${classes.check} ${!checkValid && classes.checkRed}`}>
                                <div
                                    className={`${classes.checkBox} ${check && classes.checkBoxActive}`}
                                    onClick={() => setCheck((value) => !value)}
                                ></div>
                                <p className={classes.checkText}>
                                    <Trans components={{ a: <a href="terms-conditions" target="_blank"></a> }}>
                                        {t('form.check_text')}
                                    </Trans>
                                </p>
                            </div>
                        </div>
                        <div className={classes.btnWrap}>
                            <div onClick={submit} className={`${classes.button} no-select`}>
                                <span>
                                    {t('form.btn_send')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Form;
