import React, { useEffect, useState } from "react";
import Team from "../../components/HomePage/Team/Team";
import WeOffer from "../../components/HomePage/WeOffer/WeOffer";
import IndividualOffers from "../../components/HomePage/IndividualOffers/IndividualOffers";
import Benefits from "../../components/HomePage/Benefits/Benefits";
import UnderBanner from "../../components/HomePage/UnderBanner/UnderBanner";
import Banner from "../../components/HomePage/Banner/Banner";
import Form from "../../components/GlobalComponents/Form/Form";

const HomePage = () => {
  const [ formActive, setFormActive ] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setFormActive(true);
    }, 2000);
  }, []);
  return (
    <div className="ovf-hidden">
      <Form formActive={formActive} setFormActive={setFormActive} />
      <Banner setFormActive={setFormActive} />
      <UnderBanner />
      <Benefits />
      <IndividualOffers setFormActive={setFormActive} />
      <WeOffer />
      <Team />
    </div>
  );
};

export default HomePage;
