import React from "react";
import classes from './IndividualOffers.module.scss';
import { Trans, useTranslation } from "react-i18next";
import bg from '../../../assets/img/HomePage/IndividualOffers/bg.jpg';
import Button from "../../GlobalComponents/Button/Button";

const IndividualOffers = ({ setFormActive }) => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.individualOffers}>
                <div className={classes.content}>
                    <h2 className={classes.header}>
                        <span className={`${classes.title} font-100`}>
                            {t('individual_offers.title')}
                        </span>
                        <span className={`${classes.subtitle} font-50`}>
                            {t('individual_offers.subtitle')}
                        </span>
                    </h2>
                    <p className={`${classes.text} font-18-indiv`}>
                        <Trans>
                            {t('individual_offers.text')}
                        </Trans>
                    </p>
                    <div className={classes.btn}>
                        <Button setFormActive={setFormActive}>
                            {t('btn.buy_traff')}
                        </Button>
                    </div>
                </div>
                <img className={classes.bg} src={bg} alt=''/>
            </div>
        </section>
    );
};

export default IndividualOffers;
