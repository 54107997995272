import React from "react";
import classes from './UnderBanner.module.scss';
import { useTranslation } from "react-i18next";

const UnderBanner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.underBannerWrap}>
            <div className="mt container">
                <div className={classes.underBanner}>
                    <div className={classes.content}>
                        <div className={`${classes.card} ${classes.cardSource}`}>
                            <div className="font-80">
                                4
                            </div>
                            <div>
                                <div className="font-30">
                                    {t('underbanner.text_01')}
                                </div>
                                <div className="font-23">
                                    {t('underbanner.text_02')}
                                </div>
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div className="font-23">
                                {t('underbanner.text_03')}
                            </div>
                            <div className={`${classes.lh} font-50-undb`}>
                                {t('underbanner.text_04')}
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div className="font-80">
                                20+
                            </div>
                            <div className="font-23">
                                GEO
                            </div>
                        </div>
                        <div className={classes.card}>
                            <div className="font-80">
                                200+
                            </div>
                            <div className="font-23">
                                {t('underbanner.text_05')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UnderBanner;
