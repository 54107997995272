import _ from 'lodash';
import $ from "jquery";
import i18n from "i18next";
import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useTranslation } from "react-i18next";
import classes from "./Header.module.scss";
import logo from "../../../assets/img/logo.svg";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const languages = [
  { value: "en", label: "EN" },
  { value: "ua", label: "UA" },
];

const Header = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  // const { lang } = useParams();
  const navigate = useNavigate();
  const headerRef = useRef(null);
  const lang = Cookies.get('i18next');

  const setLocale = (newLang) => {
    i18n.changeLanguage(newLang);
    // navigate(_.replace(pathname, `/${lang}/`, `/${newLang}/`));
  };

  // useEffect(() => {
  //   const langs = _.dropRight(i18n.options.supportedLngs);
  //   if (lang === undefined || !langs.includes(lang)) {
  //     setLocale('en');
  //   }
  // }, [pathname]);

  const listenScroll = () => {
    if (window.pageYOffset > 70) {
      $(headerRef.current).removeClass(classes.hideBg);
    } else {
      $(headerRef.current).addClass(classes.hideBg);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      listenScroll();
    });
    listenScroll();
  }, [pathname]);
  useEffect(() => {
    if ($("body").hasClass("lock")) {
      $("body").removeClass("lock");
    }
  }, []);
  
  return (
    <div
      ref={headerRef}
      className={`${classes.header} ${classes.hideBg} no-select`}
    >
      <div className={["container", classes.container].join(" ")}>
        <div className={classes.headerRow}>
          <div className={classes.headerRowLogo}>
            <img src={logo} alt="" />
          </div>
          <div className={classes.headerRowContent}>
            <div className={classes.langs}>
              <Dropdown
                options={languages}
                onChange={(e) => setLocale(e.value)}
                value={lang}
                placeholder="EN"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
