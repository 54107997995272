import React from "react";
import classes from "./Button.module.scss";

const Button = ({ children, setFormActive }) => {
  return (
    <div 
      onClick={() => setFormActive(true)}
      className={`${classes.button} no-select`}
    >
      <span>{children}</span>
    </div>
  );
};

export default Button;
